import { Layout } from 'components/Layout';
import React from 'react';
import { Principles } from '../../homepage/Principles';

const PrinciplesPage = ({ data }) => (
    <Layout pageTitle="Principles" siteDescription="Audentio has laid out the core principles for a community in a clear, concise manner that helps you understand how to start and grow your community effectively.">
        <Principles />
    </Layout>
);

export default PrinciplesPage;
