import { Box } from 'components/Box';
import { ContentWidth } from 'components/ContentWidth';
import { Flex } from 'components/Flex';
import { Heading } from 'components/Heading';
import { LayoutSection } from 'components/LayoutSection';
import { Paragraph } from 'components/Paragraph';
import React from 'react';
import {
    FeatureGrid,
    FeatureItem,
} from 'src/components/Landing/LandingFeatureGrid';
import { Section } from 'src/components/Section';
import { SectionHeader } from 'src/components/SectionHeader';

const principlesList = [
    {
        heading: 'foundation',
        content: 'Find simplicity and reliability in your tech-setup.',
        href: '/principles/foundation',
    },
    {
        heading: 'experience',
        content: 'Delight your users while reaching your goals.',
        href: '/principles/experience',
    },
    {
        heading: 'wellness',
        content: 'A fair and transparent community that thrives.',
        href: '/principles/wellness',
    },
    {
        heading: 'monetization',
        content: 'Redefine and discover revenue streams.',
        href: '/principles/monetization',
    },
    {
        heading: 'onboarding',
        content: 'Guided experiences that drive inclusivity and retention.',
        href: '/principles/onboarding',
    },
    {
        heading: 'engagement',
        content: 'Build the desire for a lasting connection.',
        href: '/principles/engagement',
    },
    {
        heading: 'gamification',
        content: 'Reward members for accomplishments.',
        href: '/principles/gamification',
    },
    {
        heading: 'optimization',
        content: 'Improve speeds and satisfy users.',
        href: '/principles/optimization',
    },
];

export const PrinciplesSection = () => {
    return (
        <Section wrap vertical="lg" bg="secondaryBackground">
            <SectionHeader
                title="Principles"
                subtitle="Our model for building strong communities"
                showContact={false}
                pb={['spacing-lg', '40px', 'spacing-xl']}
            />

            <Box
                bg={{
                    _: 'transparent',
                    lg: 'primaryBackground',
                }}
                mt={{
                    _: 0,
                    lg: '-120px',
                }}
                pb={{
                    _: 0,
                    lg: 'spacing-xl',
                }}
                pr={{
                    _: 0,
                    lg: 'spacing-xl',
                }}
                pt={{
                    _: 0,
                    lg: '120px',
                }}
                pl={{
                    _: 0,
                    lg: '120px',
                }}
                ml={{ _: '0', lg: '10%', xl: '20%' }}
            >
                <PrincipleList />
            </Box>
        </Section>
    );
};

const PrincipleList = () => {
    return (
        <Box as="nav">
            <FeatureGrid columns={[1, 2]} spacing="spacing-lg">
                {principlesList.map((principle, index) => (
                    <FeatureItem
                        key={index}
                        href={principle.href}
                        number={index >= 10 ? index : '0' + (index + 1)}
                        heading={principle.heading}
                        content={principle.content}
                    />
                ))}
            </FeatureGrid>
        </Box>
    );
};

export function Principles() {
    return (
        <LayoutSection>
            <ContentWidth>
                <Flex direction={{ _: 'column', lg: 'row' }}>
                    <Flex
                        flexShrink={0}
                        justify={{ lg: 'center' }}
                        width={{ _: '85%', lg: '400px' }}
                        mb={{ _: 10, lg: 0 }}
                    >
                        <Flex direction="column">
                            <Heading
                                maxWidth={{ _: '100%', lg: '180px' }}
                                as="h3"
                                fontSize="subsectionHeading"
                            >
                                principles of a community
                            </Heading>

                            <Paragraph maxWidth={{ _: '100%', lg: '200px' }}>
                                Critical aspects that make a community.
                            </Paragraph>
                        </Flex>
                    </Flex>

                    <PrincipleList />
                </Flex>
            </ContentWidth>
        </LayoutSection>
    );
}
